








































import Vue from 'vue';
import RegisterAccount from '@/components/Register.vue';

export default Vue.extend({
  components: {
    RegisterAccount,
  },
  methods: {
    redirect() {
      const { redirect } = this.$route.query;
      console.debug('[Register:redirect]', redirect);

      if (!redirect) this.$router.push('/');
      else this.$router.push(redirect.toString());
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
